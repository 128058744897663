@import './../../styles/mixins'

.article-section
  display: flex

  &:not(:last-of-type)
    margin-bottom: 150px

  &:first-of-type
    margin-top: 115px

  &:last-of-type
    margin-bottom: 200px

  &.is-default
    .h3
      max-width: 522px
      margin-left: -64px
    
    .text-group
      padding-left: 110px

  &.is-reversed
    flex-direction: row-reverse

    .h3
      position: relative
      margin-left: 263px
      margin-right: -263px

    .text-group
      padding-right: 110px
  
  &__text
    align-self: center
  
  .h3
    margin-bottom: 40px

    span
      line-height: 85px

  &--5
    .h3
      position: absolute !important
      width: 800px
    .text-group
      margin-top: 190px

  +breakpoint(medium)
    img
      max-width: 50%
      align-self: center
    &.is-reversed
      .h3
        margin-left: 0
      .text-group
        padding-right: 50px
    &.is-default
      .text-group
        padding-left: 50px

  +breakpoint(small)
    flex-direction: column

    img
      max-width: 100%

    &:first-of-type
      margin-top: 77px
    
    &.is-reversed
      flex-direction: column

      &.article-section--3
        .h3
          margin-top: -65px

    &.is-reversed, &.is-default
      .h3
        max-width: 360px
        margin: 
          top: -100px
          right: inherit 
          left: inherit 
          bottom: 20px
        span
          line-height: 45px
      
      .text-group
        padding: inherit

    &--5
      .h3
        position: relative !important
        width: inherit
      .text-group
        margin-top: 0

    &:not(:last-of-type)
      margin-bottom: 70px

    &:last-of-type
      margin-bottom: 100px
    
    .light &
      &.article-section--0, &.article-section--2
        .h3
          margin-top: -45px
