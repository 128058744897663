@import './../../styles/variables'
@import './../../styles/mixins'

$img_height: 410px

.split-view
  position: relative
  display: flex
  align-items: center
  cursor: pointer

  &.team-active, &.customer-active
    cursor: auto

  &__split
    box-sizing: border-box
    display: flex
    flex-direction: column
    width: 50%
    height: 100%
    padding-top: 187px
    padding-bottom: 80px

    &--left
      align-items: flex-end
      padding-right: 50px
      padding-left: 100px

      .split-view__h1
        right: 85px
        width: 465px

        .team-active &
          width: auto
          right: 140px

      .split-view__img
        align-self: flex-end

      .split-view__text
        max-width: 375px
    
    &--right
      overflow: hidden
      padding-right: 100px
      padding-left: 50px

      .split-view__h1
        left: 186px
      
      .split-view__logo
        left: 0
      
      .split-view__text
        max-width: 439px

  &__logo
    position: absolute
    top: 51px
    left: 50%
    margin-left: -39px
    transition: all 1s
    cursor: pointer

    &.is-left
      top: 40px
      left: 100px
      margin-left: 0
      transition: all 1s
      z-index: 5

  &__img
    width: 420px
    height: $img_height
  
  &__h1
    position: absolute
    top: 301px
    display: flex
    flex-direction: column
    line-height: 0.79

  &__text
    margin-top: 146px

  +breakpoint(medium)
    .small-viewport-helper
      position: initial

    &__h1
      top: 487px

    &__split--left
      padding-left: 50px
      .split-view__h1
        right: inherit
        left: 50px

    &__split--right
      padding-right: 50px
      .split-view__h1
        right: 50px
        left: inherit
  
  +breakpoint(small)
    flex-direction: column

    &__split
      width: 100%
      padding-top: 125px
      padding-bottom: 118px

      &--left
        align-items: flex-start
        .split-view__h1
          top: 286px

      &--right
        padding-top: 100px
        .split-view__h1
          right: inherit
          top: 261px
        .split-view__img
          float: right

    &__h1
      left: inherit
      line-height: 45px

    &__logo
      top: 20px
      .team-active &, .customer-active &
        top: 9px
        &.is-left
          left: 50%
          margin-left: -16px
        svg
          width: 32px
          height: 32px

    &__img
      width: 240px
      height: 230px

    &.team-active
      .split-view__split
        padding-top: 100px
        padding-bottom: 92px

      .split-view__split--right
        padding-top: 30px

      .split-view__h1
        bottom: -60px

    &.customer-active
      .split-view__h1
        bottom: -40px

    &.team-active, &.customer-active
      .split-view__img
        width: 100%
        height: auto
      
      .small-viewport-helper
        position: relative

      .split-view__h1
        top: inherit
        left: 0
  
.team-intro
  justify-content: center
  min-height: $img_height

  +breakpoint(xlarge)
    max-width: 800px

.customer-active
  height: 700px

  +breakpoint(small)
    height: auto

.customer
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 50%
  min-height: 100vh

  .customer-active &
    left: 0
    width: 50%

  +breakpoint(small)
    position: inherit
    left: inherit
    min-height: 614px

    .customer-active &
      left: 0
      width: 100%
      padding-bottom: 50px

.customer-intro
  position: absolute
  height: 100vh
  top: 0
  bottom: 0
  right: 0
  margin-top: 99px

  +breakpoint(small)
    position: relative
    height: auto
    margin-top: 20px
    padding-top: 0
    padding-bottom: 57px

.small-viewport-helper
  position: relative
