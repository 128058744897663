@import './../../styles/mixins'
@import './../../styles/variables'

.form-section
  margin:
    left: -$page-padding
    right: -$page-padding
    bottom: 200px
  
  .box-highlight
    display: flex
    justify-content: center
    align-items: center

    .h3
      max-width: 450px
      line-height: 75px
  
  .form
    padding: 60px $page-padding 60px 120px

  +breakpoint(medium)
    .form
      padding-left: 60px

  +breakpoint(small)
    margin:
      left: -$page-padding-small
      right: -$page-padding-small
      bottom: 100px

    .box-highlight
      padding-top: 100px
      padding-bottom: 100px
      padding-right: $page-padding-small
      padding-left: $page-padding-small

      .h3
        line-height: 45px

    .form
      padding-right: $page-padding-small
      padding-left: $page-padding-small
      padding-bottom: 0

.checkbox-wrapper
  display: flex
  align-items: center
  margin-top: 40px
  margin-bottom: 40px
