@import './../../styles/mixins'
@import './../../styles/variables'

.header
  +breakpoint(small)
    position: fixed
    z-index: 4
    right: 0
    top: 0
    left: 0
    padding-right: $page-padding-small
    padding-left: $page-padding-small

    .light &
      background-color: $white
  
    .dark &
      background-color: $black

.nav-bar
  display: none
  position: relative
  width: 27px
  height: 47px
  padding: 20px 0
  cursor: pointer
  margin-left: auto

  &::before, &::after 
    transition: transform .5 ease
    content: ''
    position: absolute
    width: 27px
    height: 1px
    transform: rotate(0)

    .light &
      background-color: $black
  
    .dark &
      background-color: $white
  
  &::before
    top: 20px
  
  &::after
    bottom: 20px

  &.is-active
    &::before
      transition: transform .5 ease
      top: 23px
      transform: rotate(45deg)

    &::after
      transition: transform .5 ease
      bottom: 23px
      transform: rotate(-45deg)

  +breakpoint(small)
    display: block
    
.navigation
  position: absolute
  top: 67px
  left: 236px
  display: flex
  list-style: none
  margin: 0

  li
    &:not(:last-of-type)
      margin-right: 60px
  
  +breakpoint(small)
    display: none
    position: fixed
    top: 46px
    left: 0
    right: 0
    padding-top: 10px
    padding-right: 30px
    padding-left: 30px
    flex-direction: column
    align-items: center
    justify-content: center

    .light &
      background-color: $white
  
    .dark &
      background-color: $black

    &.is-active
      display: flex

    li
      padding: 10px

      &:not(:last-of-type)
        margin-right: 0
