.scroll-down
  text-align: center
  margin-top: 10px

  svg
    animation: rotation 10s infinite linear

@keyframes rotation
  from
    transform: rotate(0deg)
  to
    transform: rotate(359deg)