@import './../../styles/mixins'
@import './../../styles/variables'
  
$number_images: 18
$image_width: 250px
$image_space: 80px
$image_padding: 8px
$total_image_width: calc($image_width + $image_space)

.partner-banner
  overflow: hidden
  width: 100vw
  padding-left: $page-padding
  margin:
    left: -$page-padding
    right: -$page-padding
  +breakpoint(small)
    margin:
      left: -$page-padding-small
      right: -$page-padding-small

  .dark &
    background-color: $secondary

  &__list
    display: flex
    align-items: center
    animation: partnerslide 60s infinite linear

  &__list-item
    margin-right: $image_space
    padding: $image_padding

    img
      max-width: $image_width
      max-height: 150px
  .space-helper + li
    margin-left: 20px

  +breakpoint(small)
    &__list
      padding-right: calc($page-padding-small * 2)
    
    &__list-item
      img
        width: 150px

@keyframes partnerslide
  0%
    transform: translate3d(0, 0, 0)
  100%
    transform: translate3d(calc(($number_images * $total_image_width - $page-padding)*-1), 0, 0)
