=breakpoint($device)
  @if $device == "xlarge"
    @media only screen and (min-width: 1600px)
      @content
  @if $device == "large"
    @media only screen and (max-width: 1600px)
      @content
  @if $device == "medium"
    @media only screen and (max-width: 1199px)
      @content
  @else if $device == "small"
    @media only screen and (max-width: 912px)
      @content

=hide-scrollbar
  -ms-overflow-style: none
  scrollbar-width: none

  &::-webkit-scrollbar
    display: none
