.about-section
  position: relative

  .light &
    .h3
      span
        display: block

  .rotate-headline
    top: 729px

  +breakpoint(small)
    .rotate-headline
      top: 511px

.services-section
  position: relative

  .rotate-headline
    top: 368px
    letter-spacing: -5px

.team-structure-section
  position: relative
  padding-top: 120px
  margin-bottom: 260px

  .rotate-headline
    letter-spacing: -6px
    top: 487px

  +breakpoint(small)
    padding-top: 0px

    .rotate-headline
      top: 332px
      width: 600px
      letter-spacing: inherit

.partner-section
  margin-top: 260px
  margin-bottom: 300px

  .light &
    margin-top: 150px

  .h3 span
    display: block
    line-height: 75px
  
  +breakpoint(small)
    margin-top: 100px
    margin-bottom: 100px

    .h3 span
      line-height: inherit

.onoard-section
  position: relative
  margin-bottom: 200px

  .marquee
    margin-bottom: 150px

  .rotate-headline
    top: 470px
  
  +breakpoint(small)
    margin-bottom: 100px

    .rotate-headline
      width: 355px

.onboard-questions
  display: flex
  flex-wrap: wrap

  .box-highlight
    max-width: 630px
    margin-bottom: 80px
  
  &__number
    width: 25%

  &__text
    width: 75%
    margin-bottom: 60px

    &.h3
      font-size: 70px
      padding-top: 15px
      line-height: 75px
  
  +breakpoint(small)
    flex-direction: column
    &__text, &__number
      width: 100%

      &.h3
        line-height: inherit

    .box-highlight
      max-width: 100%
      margin-bottom: 10px

.footer-section
  align-items: center

  .h3
    margin-bottom: 60px
    line-height: 75px

  .contact
    padding-right: 65px
    margin-bottom: 57px

  .impress
    padding-left: 120px
  
  .footer-nav
    display: flex
    align-items: center
    justify-content: space-between
    flex-wrap: wrap
    flex-direction: row-reverse
    margin-bottom: 50px

    .text-outline
      overflow: hidden

    &__links
      display: flex

    &__link
      cursor: pointer
      &:hover
        text-decoration: underline
      &:first-of-type
        margin-right: 60px
  
  .social-icons
    float: right
    margin-top: -26px
    img
      width: 53px
      height: 53px
    
  +breakpoint(medium)
    .h1
      font-size: min(200px, 17vw)
      margin-right: auto

    .social-icons
      margin-top: 20px

  +breakpoint(small)
    margin-bottom: 100px

    .contact
      padding-right: 0
    
    .impress
      padding-left: 0px

    .h3
      line-height: 45px
    
    .footer-nav
      flex-direction: column
      align-items: flex-start

      &__links
        margin-bottom: 30px
        display: flex
        flex-direction: column

      &__link:first-of-type
        margin-right: 0
        margin-bottom: 20px
