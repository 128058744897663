@import './../../styles/mixins'

.accordion
  width: 100%
  display: flex
  flex-wrap: nowrap
  overflow: hidden
  width: 100%
  height: 735px

  &:hover
    .accordion__item
      border-width: 2px

  &__item
    position: relative
    display: flex
    align-items: center
    width: 75%
    flex-grow: 1
    flex-shrink: 1
    overflow: hidden
    transition: all .5s ease
    border: 5px solid transparent
    position: relative

    &:hover
      flex-shrink: 0

      .accordion__text
        display: flex

  &__text
    padding: 0 76px
    display: none
    position: absolute
    left: 0
    right: 0
    flex-direction: column
    z-index: 3

    .h3
      margin-bottom: 40px
      letter-spacing: -2px
      line-height: 75px
      word-wrap: break-word
      max-width: 100%

    .text-outline
      -webkit-text-stroke-width: 1px
    
    .box-highlight
      padding: 60px 90px
      max-width: 100%
      align-self: flex-end

  &__image
    width: 100%
    height: 100%
    object-fit: cover
    filter: brightness(0.5)

    &--4
      object-position: 38% 50%
  
  +breakpoint(small)
    flex-direction: column
    height: 660px

    &__item
      width: 100%
      align-items: flex-start

      &:hover
        height: 400px
        overflow-y: scroll
        +hide-scrollbar

    
    &__text
      padding: 20px 36px

      .box-highlight
        padding: 16px 16px
        max-width: 100%

      .h3
        line-height: 55px
